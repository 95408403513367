.base-button-wrapper {
    font-family: Corbel, "Roboto";
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: fit-content;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    padding-right: 24px;
    padding-left: 24px;
    width: fit-content;
    white-space: wrap;
    text-overflow: ellipsis;
}

.base-button-wrapper button {
    width: 100%;
    color: #FFF;
    text-align: center;
    font-family: Corbel, "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0px;
    width: fit-content;
    white-space: wrap;
    text-overflow: ellipsis;
}

.base-button-wrapper a {
    text-decoration: none;
    color: #FFF;
    text-align: center;
    font-family: Corbel, "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0px;
    width: fit-content;
    white-space: wrap;
    text-overflow: ellipsis;
}

.base-button-wrapper:active {
    transform: translateY(2px);
}

.base-button-wrapper a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.base-button-wrapper button {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    border-radius: 15px;
    cursor: pointer;
}

@media(max-width:550px) {
    .base-button-wrapper p {
        font-size: 16px;
    }
}