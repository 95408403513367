.form-newsletter-wrapper {
    width: 513px;
    height: 100%;
    margin-bottom: 1rem;
}

.form-newsletter {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    column-gap: 20px;
}

.newsletter-input {
    width: 100%;
    height: 51px;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    margin-top: 10px;
    font-size: 18px;
}

.newsletter-input input {
    width: 361px;
    border: 1px solid #000000;
    border-radius: 10px;
    padding-left: 20px;
    font-size: 18px;
}

.newsletter-input input::placeholder {
    color: #BCBEC0;
    font-family: Corbel, "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.newsletter-input button {
    height: 48px;
    background-color: #E0871A;
    color: white;
    font-size: 20px;
    border: none;
    border-radius: 10px;
    width: fit-content;
    white-space: wrap;
    text-overflow: ellipsis;
    font-family: Corbel, "Roboto";
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    padding: 12px 24px;
}

#newsletter-term p {
    color: #58595B;
    font-family: Corbel, "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0px;
}

#newsletter-term a:visited,
#newsletter-term a:active {
    color: #58595B;
}

#newsletter-term a:hover {
    color: #E0871A;
}


/* Sign up component styles */

.signup-feature-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 112px;
    margin-top: 112px;
}

.signup-feature-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signup-content-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 35px;
}

.signup-content-header h3 {
    color: #223476;
    font-family: "Titillium Web", "Roboto";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0px;
}

.signup-content-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 80px;
    justify-content: space-between;
}

.signup-content-icon {
    width: 50%;
    display: flex;
    align-items: center;
    padding-left: 70px;
}

.signup-content-bar {
    width: 50%;
}

.signup-content-bar p {
    color: #58595B;
    font-family: Corbel, "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    max-width: 616px;
}

@media (max-width: 560px) {
    .form-newsletter-wrapper,
    .newsletter-input input,
    .newsletter-input button {
        width: 90%;
    }
    .newsletter-input {
        height: 102px;
    }
    .newsletter-input input,
    .newsletter-input button {
        height: 51px;
        text-align: center;
    }
    .newsletter-input {
        flex-direction: column;
        row-gap: 10px;
    }
    .newsletter-input input,
    .newsletter-input button {
        width: auto;
    }
    .signup-content-icon {
        padding-left: 0px;
    }
}

@media(max-width:680px) {
    .signup-feature-wrapper {
        margin-bottom: 60px;
        margin-top: 60px;
    }
    .signup-content-header {
        justify-content: center;
    }
    .signup-content-header h3 {
        font-size: 30px;
        text-align: center;
    }
    .signup-content-container {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        row-gap: 24px;
    }
    .signup-content-icon {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0px;
    }
    .signup-content-bar {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .signup-content-bar p {
        max-width: 616px;
        text-align: center;
        font-size: 14px;
    }
}

@media(min-width:680px) and (max-width:899px) {
    .signup-content-header {
        justify-content: center;
    }
    .signup-content-container {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        row-gap: 24px;
    }
    .signup-content-icon {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0px;
    }
    .signup-content-bar {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .signup-content-bar p {
        max-width: 616px;
        text-align: center;
    }
}

@media(min-width:900px) and (max-width:1230px) {
    .signup-content-header {
        justify-content: center;
    }
    .signup-content-container {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        row-gap: 24px;
    }
    .signup-content-icon {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .signup-content-bar {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .signup-content-bar p {
        max-width: 616px;
        text-align: center;
    }
}