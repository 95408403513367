@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
:root {
    --font-primary: 'Titillium Web', sans-serif;
    /* --font-size-normal: 16px;
    --font-size-large: 18px;
    --font-size-small: 14px; */
}

html {
  scroll-behavior: smooth;
}

*{
    transition: .3s all ease-in-out;
}

body {
    font-family: var(--font-primary);
    /* font-size: var(--font-size-normal); */
}

h1,
h2,
h3,
h4,
h5,
h6 {
    /* margin: 0; */
    /* font-weight: normal; */
}

h1 {
    font-size: 56px;
    margin: 0;
    font-weight: 700;
    color: #1C2D5A;
}

h2 {
    font-size: 48px;
    font-weight: 700;
    color: #1C2D5A;
}

h3 {
    font-size: 38px;
    color: #1C2D5A;
}

h4 {
    font-size: 24px;
    color: #1C2D5A;
}

h5 {
    font-size: 20px;
    margin: 0;
    color: #1C2D5A;
}

h6 {
    font-size: 18px;
    color: #1C2D5A;
}

p {
    font-size: 18px;
    /* font-size: var(--font-size-normal);
margin-bottom: 1rem; */
}

small,
.text-small {
    font-size: var(--font-size-small);
}

@media (max-width: 680px) {
    h1 {
        font-size: 30px;
    }
    h2 {
        font-size: 30px;
    }
    h3 {
        font-size: 24px;
    }
    h4 {
        font-size: 16px;
    }
    h5 {
        font-size: 16px;
    }
    h6 {
        font-size: 16px;
    }
    p {
        font-size: 14px;
    }
}

@media(min-width:680px) and (max-width: 900px) {}