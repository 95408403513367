.form-wrapper-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: scroll;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.form-container {
    width: 90%;
    max-width: 700px;
    margin: 20px 20px;
    padding: 24px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-header {
    border-bottom: 2px solid #E0871A;
    margin-bottom: 1rem;
    font-size: 30px;
    color: rgba(34, 52, 118, 1);
}

.join-beta-popup-close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.join-beta-popup-close button {
    border: none;
    font-size: 20px;
    background-color: transparent;
}

.form-header h2 {
    font-size: 30px;
    color: rgba(34, 52, 118, 1);
    margin: 10px 0px;
}

.join-beta-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    width: 95%;
    margin-bottom: 20px;
}

.form-fullname {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.form-first-name {
    width: 100%;
}


/* .form-last-name {
    width: 53%;
} */

.form-group label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-size: 16px;
}

.form-group input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.form-group select {
    margin-right: 0;
    width: 104%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    gap: 16px;
    /* Code below change arrow */
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

.submit-btn {
    background-color: #223476;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #E0871A;
}

.toast-message {
    margin-top: 20px;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    text-align: center;
    border-radius: 4px;
}

@media (max-width: 370px) {
    .form-fullname {
        display: flex;
        flex-direction: column;
    }
    .form-first-name {
        width: 100%;
    }
    .form-last-name {
        width: 100%;
    }
    .form-group select {
        width: 108%;
    }
    .form-group select {
        margin-right: 0;
        width: 109%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 14px;
        gap: 16px;
        /* Code below change arrow */
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 1rem center;
        background-size: 1em;
    }
}