.ourSolution-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ourSolutions-desicion-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    row-gap: 86px;
}

.ourSolutions-desicion-container-one,
.ourSolutions-desicion-container {
    height: 100%;
    max-height: 726px;
    max-width: 1246px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 80px;
    align-items: center;
    margin-left: 97px;
    margin-right: 97px;
}

.ourSolutions-desicion-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48%;
    max-height: 640px;
}

.ourSolutions-desicion-img img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 25px;
}

.ourSolutions-desicion-content {
    width: 48%;
    min-height: 396px;
    gap: 24px;
}

.ourSolutions-desicion-content h2 {
    color: #1C2D5A;
    font-family: "Titillium Web", "Roboto";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    margin-bottom: 0px;
}

.ourSolutions-desicion-content p {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.future-apps-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 112px;
}

.future-apps-wrapper h3 {
    color: var(--Main-color1, #1C2D5A);
    text-align: center;
    font-family: "Titillium Web";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0px;
}

.future-apps-container {
    width: 100%;
    max-width: 1312px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    row-gap: 112px;
    column-gap: 48px;
    margin-top: 112px;
}

@media(max-width:680px) {
    .ourSolutions-desicion-wrapper {
        width: 100%;
        row-gap: 43px;
        margin-top: 0px;
        /* margin-bottom: 112px; */
    }
    .ourSolutions-desicion-container-one {
        max-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;
    }
    .ourSolutions-desicion-container {
        max-height: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;
    }
    .ourSolutions-desicion-img {
        width: 90%;
        max-height: auto;
    }
    .ourSolutions-desicion-img img {
        max-width: 90%;
        max-height: 100%;
        border-radius: 25px;
    }
    .ourSolutions-desicion-content {
        width: 90%;
        min-height: 100%;
        margin-bottom: 24px;
        gap: 24px;
    }
    .ourSolutions-desicion-content h2 {
        color: #1C2D5A;
        font-family: "Titillium Web", "Roboto";
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
    }
    .ourSolutions-desicion-content p {
        font-size: 14px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
    }
    .future-apps-wrapper h3 {
        font-size: 30px;
    }
    .future-apps-container {
        row-gap: 60px;
        column-gap: 48px;
        margin-top: 60px;
    }
}

@media(min-width:680px) and (max-width:780px) {
    .ourSolutions-desicion-wrapper {
        row-gap: 0px;
        margin-top: 43px;
        /* margin-bottom: 43px; */
    }
    .ourSolutions-desicion-container-one {
        max-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .ourSolutions-desicion-container {
        max-height: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .ourSolutions-desicion-img {
        width: 90%;
        max-height: 640px;
    }
    .ourSolutions-desicion-img img {
        max-width: 90%;
        max-height: 100%;
        border-radius: 25px;
    }
    .ourSolutions-desicion-content {
        width: 90%;
        min-height: 100%;
        gap: 24px;
    }
    .ourSolutions-desicion-content h2 {
        color: #1C2D5A;
        font-family: "Titillium Web", "Roboto";
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
    }
    .ourSolutions-desicion-content p {
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
    }
    .future-apps-container {
        row-gap: 60px;
        column-gap: 48px;
        margin-top: 60px;
    }
}

@media(min-width: 780px) and ( max-width:900px) {
    .ourSolutions-desicion-wrapper {
        row-gap: 0px;
        margin-top: 43px;
        /* margin-bottom: 43px; */
    }
    .ourSolutions-desicion-container-one {
        height: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        /* row-gap: 83px; */
        margin-bottom: 40px;
    }
    .ourSolutions-desicion-container {
        max-height: 100%;
        height: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: center;
    }
    .ourSolutions-desicion-img {
        width: 80%;
        max-height: 640px;
    }
    .ourSolutions-desicion-img img {
        max-width: 90%;
        max-height: 100%;
        border-radius: 25px;
    }
    .ourSolutions-desicion-content {
        width: 80%;
        min-height: 100%;
        gap: 24px;
        margin-bottom: 24px;
    }
    .ourSolutions-desicion-content h2 {
        color: #1C2D5A;
        font-family: "Titillium Web", "Roboto";
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
    }
    .ourSolutions-desicion-content p {
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
    }
    .future-apps-container {
        row-gap: 60px;
        column-gap: 48px;
        margin-top: 60px;
    }
}

@media(min-width: 900px) and ( max-width:1000px) {
    .ourSolutions-desicion-wrapper {
        row-gap: 43px;
        /* margin-top: 43px; */
        /* margin-bottom: 43px; */
    }
    .ourSolutions-desicion-container-one,
    .ourSolutions-desicion-container {
        min-height: 100%;
        height: 100%;
    }
    .ourSolutions-desicion-content h2 {
        font-size: 34px;
    }
    .ourSolutions-desicion-content p {
        font-size: 16px;
    }
}

@media(min-width: 1000px) and ( max-width:1100px) {
    .ourSolutions-desicion-container-one,
    .ourSolutions-desicion-container {
        height: 100%;
    }
    .ourSolutions-desicion-content h2 {
        font-size: 34px;
    }
    .ourSolutions-desicion-content p {
        font-size: 16px;
    }
}