@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400;500;600;700;800;900&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.culture-card-wrapper {
    width: 100%;
    max-width: 416px;
    min-height: 566px;
    border: 1px solid #D3AF37;
    border-radius: 20px;
    overflow: hidden;
}

.culture-card-wrapper a {
    text-decoration: none;
}

.culture-card-wrapper:active {
    transform: translateY(2px);
}

.culture-card-img {
    max-width: 100%;
    height: 300px;
}

.culture-card-content {
    max-width: 100%;
    min-height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.culture-card-content-head h3 {
    width: 90%;
    margin-left: 24px;
    color: #1C2D5A;
    font-family: "Titillium Web", "Roboto";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-top: 4px;
    margin-bottom: 8px;
}

.culture-card-content p {
    max-width: 90%;
    color: #58595B;
    font-family: Corbel, "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-left: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    letter-spacing: 0em;
    text-align: left;
}


/* .culture-card-content p {
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
} */


/* Card type section */

.culture-card-content-top {
    width: 152px;
    height: 29px;
    display: inline-flex;
    align-items: center;
    gap: 16px;
    margin-left: 24px;
    margin-top: 24px;
    margin-bottom: 24px;
}

.culture-card-content-type {
    min-width: 60px;
    display: flex;
    padding: 4px 8px;
    align-items: flex-start;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    color: white;
    background: #E0871A;
    text-align: center;
}

.culture-card-content-top p {
    color: #FFF;
    font-family: Corbel, "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    margin: 0px;
    letter-spacing: 0em;
}

.culture-card-content-top h6 {
    width: fit-content;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #4B5DAA;
    font-family: "Titillium Web", "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin: 0px;
}

.culture-card-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
    margin-left: 24px;
    margin-bottom: 10px;
    /* margin-bottom: 34px;
    margin-top: 39px; */
}

.culture-card-icon a {
    color: #4B5DAA;
}

.culture-card-icon a:visited,
.culture-card-icon a:hover,
.culture-card-icon a:active {
    color: #4B5DAA;
}

.culture-card-content .culture-card-icon p {
    margin-left: 0px;
    color: #4B5DAA;
    font-family: "Titillium Web", "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

@media(max-width: 680px) {
    .culture-card-wrapper {
        max-width: 416px;
        min-height: auto;
    }
    .culture-card-img {
        max-width: 416px;
        height: 300px;
    }
    .culture-card-content-head h3 {
        margin-left: 18px;
    }
    .culture-card-content-head p {
        font-size: 14px;
    }
    .culture-card-content-top p {
        font-size: 14px;
    }
    .culture-card-content .culture-card-icon p {
        font-size: 12px;
    }
}

@media(min-width:900px) and (max-width: 1025px) {
    .culture-card-content-head {
        height: 150px;
    }
    .culture-card-content-head h3 {
        font-size: 20px;
    }
    .culture-card-content-head p {
        font-size: 14px;
    }
    .culture-card-content-top p {
        font-size: 14px;
    }
}