.allServiceContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.allServiceContainer h2 {
    /* font-family: Inter; */
    font-size: 55px;
    font-weight: 600;
    line-height: 67px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 0px;
}

.allServiceWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.allServiceFirstColumn {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: -70px;
}

.allServiceSecondColumn {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.allServiceThirdColumn {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
}