.aboutWrapper {
    width: 100%;
    height: 100%;
}

.benefitRowWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 55px;
    margin-top: 75px;
}

.cultureBlogWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 112px;
    margin-bottom: 43px;
}

.cultureBlogTop h4 {
    color: #58595B;
    text-align: center;
    font-family: "Titillium Web", "Roboto";
    font-size: 30px;
    font-style: normal;
    font-weight: 275;
    line-height: 150%;
    margin: 0px;
}

.cultureBlogHead h3 {
    font-family: 'Titillium Web', "Roboto";
    font-size: 48px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(34, 52, 118, 1);
    margin-top: 16px;
    margin-bottom: 24px;
}

.cultureBlogHeadSubtitle {
    width: 90%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.cultureBlogHeadSubtitle p {
    font-size: 25px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0px;
    color: #58595B;
    margin: 0px;
}

.cultureBlogCards {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 32px;
    margin-top: 48px;
}

@media(max-width: 680px) {
    .cultureBlogWrapper {
        margin-top: 60px;
        margin-bottom: 43px;
    }
    .cultureBlogTop h4 {
        font-size: 20px;
    }
    .cultureBlogHead h3 {
        font-size: 30px;
    }
    .cultureBlogCards {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        row-gap: 32px;
    }
    .cultureBlogHeadSubtitle p {
        max-width: 80%;
        font-size: 18px;
    }
}

@media(min-width:680px) and (max-width:900px) {
    .cultureBlogCards {
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        row-gap: 32px;
    }
    .cultureBlogTop h4 {
        font-size: 22px;
    }
    .cultureBlogHead h3 {
        font-size: 34px;
    }
    .cultureBlogCards {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        row-gap: 32px;
    }
    .cultureBlogHeadSubtitle p {
        max-width: 80%;
        font-size: 20px;
    }
}