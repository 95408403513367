.card-container {
    width: 632px;
    height: 459px;
    border: 1px solid #D3AF37;
    border-radius: 5%;
    overflow: hidden;
    /* margin: auto; */
}

.card-img {
    max-width: 632px;
    height: 300px
}

.card-content-wrapper {
    height: 159px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-content {
    width: 100%;
    max-width: 584px;
    height: 111px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 24px;
}

.card-content-category {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: 10px;
    margin-top: 0px;
    margin-bottom: 10px;
    text-decoration: none;
}

.card-content-category h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    padding: 4px 8px 4px 8px;
    border-radius: 5px;
    background-color: #E0871A;
    color: white;
    margin: 0px;
}

.card-content-category h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    padding: 4px, 8px, 4px, 8px;
    color: #4B5DAA;
    margin-top: 5px;
    margin-bottom: 5px;
    text-decoration: none;
}

.card-content h3 {
    font-family: "Titillium Web", "Roboto";
    color: #1C2D5A;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin: 0px;
}

.card-content p {
    color: #58595B;
    font-family: Corbel, "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin: 0px;
}

.cardList-learn-more {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #25BDBE;
    bottom: 0;
    margin-top: 10px;
    margin-bottom: 10px;
}

.cardList-learn-more a {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #4B5DAA;
    bottom: 0;
    margin-top: 0px;
    margin-bottom: 0px;
}

.cardList-learn-more a:hover,
.cardList-learn-more a:active,
.cardList-learn-more a:visited {
    color: #4B5DAA;
}

@media(max-width:475px) {
    .card-container {
        width: 90%;
        min-height: 459px;
        margin: auto;
    }
    .card-img {
        max-width: 632px;
        max-height: 300px;
    }
    .card-content {
        max-width: 90%;
        margin-left: 24px;
    }
    .card-content h3 {
        font-size: 18px;
        margin-top: 0px;
    }
    .card-content p {
        font-size: 14px;
        margin-bottom: 0px;
    }
    .cardList-learn-more {
        font-size: 14px;
    }
}

@media(min-width:475px) and (max-width:680px) {
    .card-container {
        width: 464px;
        height: 459px;
    }
    .card-img {
        max-width: 632px;
        max-height: 300px;
    }
    .card-content {
        max-width: 90%;
        margin-left: 24px;
    }
    .card-content h3 {
        font-size: 20px;
        margin-top: 5px;
    }
    .card-content p {
        font-size: 14px;
        margin-bottom: 0px;
    }
    .cardList-learn-more {
        font-size: 14px;
    }
}