.getInTouch-wrapper {
    width: 100%;
    height: auto;
    padding-top: 112px;
    padding-bottom: 112px;
    background-color: #E0871A;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 48px;
}

.form-wrapper-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: scroll;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.join-beta-form-wrapper {
    width: 90%;
    max-width: 700px;
    margin: 20px 20px;
    padding: 24px;
    background-color: #E0871A;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.getInTouch-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.getInTouch-header h5 {
    margin: 0;
    color: #F8FAFF;
    text-align: center;
    font-family: "Titillium Web", "Roboto";
    font-size: 30px;
    font-style: normal;
    font-weight: 275;
    line-height: 150%;
}

.getInTouch-header h3 {
    margin: 0;
    color: #1C2D5A;
    text-align: center;
    font-family: "Titillium Web", "Roboto";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-top: 16px;
    margin-bottom: 24px;
}

.getInTouch-header p {
    max-width: 635px;
    margin: 0;
    color: #F8FAFF;
    text-align: center;
    font-family: Corbel, "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.contact-form {
    display: flex;
    width: 90%;
    max-width: 768px;
    max-height: auto;
    flex-direction: column;
    align-items: center;
    margin: auto;
    gap: 24px;
    flex-shrink: 0;
}

.form-wrap {
    width: 95%;
}

.getInTouch-fullname {
    width: 97%;
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    justify-content: space-between;
}

.getInTouch-first-name,
.getInTouch-last-name {
    width: 50%;
}

.contact-role {
    width: 97%;
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    justify-content: space-between;
}

.form-email-address,
.role-wrapper {
    width: 50%;
}

.form-wrap label {
    display: block;
    margin-bottom: 8px;
    color: var(--MAIN-BODY-COLOR, #58595B);
    font-family: "Titillium Web", "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.apply-bottom {
    background-color: #223476;
    color: #ffffff;
    font-family: Corbel, "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    height: 48px;
    width: 136px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    border-radius: 10px;
    border: 1px solid var(--Main-color1, #1C2D5A);
    background: var(--Main-color1, #1C2D5A);
    transition: background-color 0.3s ease;
}

.apply-bottom:hover {
    background-color: #1C2D5A;
}

.apply-bottom:active {
    transform: translateY(2px);
}

.form-wrap input[type="text"] {
    width: 97%;
    display: flex;
    height: 16px;
    padding: 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid #58595B;
    background: #FFF;
}

.form-wrap select {
    display: block;
    width: 105%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 10px;
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid #58595B;
    background: #FFF;
    /* Code below change arrow */
    /* found it here https: //stackoverflow.com/questions/51701666/how-to-change-the-position-of-a-drop-down-arrow-in-css */
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

.form-wrap textarea {
    width: 97%;
    height: 180px;
    display: flex;
    height: 180px;
    padding: 12px;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid #000;
    background: #FFF;
    resize: none;
}

#interestReason {
    color: #58595B;
    font-family: Corbel, "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

::placeholder {
    color: #BCBEC0;
    font-family: Corbel, "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%
}

@media(max-width:369px) {
    .getInTouch-wrapper {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .getInTouch-header {
        width: 90%;
    }
    .getInTouch-header h5 {
        font-size: 20px;
    }
    .getInTouch-header h3 {
        font-size: 30px;
    }
    .getInTouch-header p {
        width: 90%;
        font-size: 14px;
    }
    .contact-form {
        display: flex;
        justify-content: center;
        margin-right: 20px;
    }
    .getInTouch-fullname {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        justify-content: space-between;
    }
    .getInTouch-first-name,
    .getInTouch-last-name {
        width: 100%;
    }
    .contact-role {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        justify-content: space-between;
    }
    .form-email-address,
    .role-wrapper {
        width: 100%;
    }
    .form-wrap label {
        font-size: 14px;
    }
    .form-wrap select {
        width: 107%;
    }
    #interestReason {
        font-size: 14px;
    }
     ::placeholder {
        font-size: 14px;
    }
}

@media(min-width:370px) and (max-width:680px) {
    .getInTouch-wrapper {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .getInTouch-header h5 {
        font-size: 20px;
    }
    .getInTouch-header h3 {
        font-size: 30px;
    }
    .getInTouch-header p {
        width: 90%;
        font-size: 14px;
    }
    .getInTouch-fullname {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        justify-content: space-between;
    }
    .getInTouch-first-name,
    .getInTouch-last-name {
        width: 100%;
    }
    .contact-role {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        justify-content: space-between;
    }
    .form-email-address,
    .role-wrapper {
        width: 100%;
    }
    .form-fullname {
        display: flex;
        flex-direction: column;
    }
    .form-first-name {
        width: 100%;
    }
    .form-last-name {
        width: 100%;
    }
    .form-wrap select {
        width: 102%;
    }
}