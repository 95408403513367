.maxEfficenty-container {
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E5CA9C;
    padding-top: 112px;
    padding-bottom: 112px;
}

.maxEfficenty-wrapper {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.maxEfficenty-header-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

.maxEfficenty-header-title h3 {
    margin-top: 0px;
    margin-bottom: 30px;
    color: #1C2D5A;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0px;
}

.maxEfficenty-header-description {
    height: 73px;
    display: flex;
    align-items: center;
    border-left: 10px solid #E0871A;
}

.maxEfficenty-header-description p {
    padding-left: 30px;
    top: 102px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px;
}

.maxEfficenty-buttons {
    width: 345px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    column-gap: 13px;
    margin-bottom: 24.45px;
}

@media(max-width:500px) {
    .maxEfficenty-container {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .maxEfficenty-header-title h3 {
        margin-top: 0px;
        margin-bottom: 0px;
        color: #1C2D5A;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }
    .maxEfficenty-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .maxEfficenty-buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        column-gap: 13px;
        margin-bottom: 24.45px;
        margin-top: 30px;
    }
}

@media(min-width:500px) and (max-width: 900px) {
    .maxEfficenty-container {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .maxEfficenty-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 24px;
        margin-top: 60pxpx;
    }
    .maxEfficenty-buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        column-gap: 13px;
        margin-bottom: 24.45px;
    }
    .maxEfficenty-buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        column-gap: 13px;
        margin-bottom: 24.45px;
        margin-top: 30px;
    }
}