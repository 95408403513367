.blog-page-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blog-posts-container {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


/* design in CEO.tsx */

.blog-post-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blog-post-first-section {
    width: 90%;
    max-width: 1250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 112px;
    margin-bottom: 112px;
}

.blog-header-path {
    width: 100%;
    max-width: 1250px;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
}

.blog-content {
    width: 100%;
}

.blog-post-title {
    width: 100%;
    max-width: 1250px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.blog-post-title h2 {
    color: #223476;
    font-family: "Titillium Web", "Roboto";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0px;
}

.blog-subtitle-container {
    width: 100%;
    max-width: 1250px;
    min-height: 48px;
    max-width: 1250px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 86px;
    margin-top: 41px;
}

.blog-date-time {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
}

.blog-date-time p {
    color: #1C2D5A;
    font-family: "Titillium Web", "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0px;
}

.blog-social-icon-wrapper {
    height: 48px;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
}

.blog-social-icon {
    display: flex;
    width: 48px;
    height: 48px;
    /* padding: 12px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 64px;
    background: #25BDBE;
}

.image-stack {
    width: 100%;
    max-width: 1250px;
    height: 547px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-shrink: 0;
    border-radius: 20px;
    opacity: 1.0;
    background: url("../images/ceo1.png"), lightgray 50% / cover no-repeat;
}

.stacked-image-front {
    position: absolute;
    bottom: 0px;
    right: 0px;
    filter: opacity(1);
}

.blog-date-icon {
    width: 48px;
    height: auto;
    flex-shrink: 0;
}

.blog-subtitle-small_media {
    display: none;
}

.blog-time-wrapped {
    display: none;
}

.blog-time {
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex-wrap: nowrap;
}

.blog-post-second-section {
    width: 100%;
    height: 100%;
    padding-top: 112px;
    padding-bottom: 112px;
    background: #E0871A;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blog-post-content-container {
    width: 90%;
    max-width: 1250px;
}

.blog-post-content-container h3 {
    color: #223476;
    font-family: "Titillium Web", "Roboto";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0px;
}

.blog-post-content-container p {
    color: #F8FAFF;
    font-family: Corbel, "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.blog-post-second-img {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 46px;
    margin-bottom: 46px;
}

.blog-post-second-img img {
    width: 100%;
    max-width: 1250px;
    height: 100%;
}

.blog-post-content-quotes {
    display: inline-flex;
    height: 100px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.blog-post-content-quotes p {
    color: #1C2D5A;
    font-family: Corbel, "Roboto";
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    line-height: 28px;
}

.blog-posts-container {
    width: 90%;
    max-width: 1312px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: space-evenly;
    justify-items: center;
    /* display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; */
    margin-top: 82px;
    column-gap: 32px;
    row-gap: 64px;
}

@media(max-width: 440px) {
    .blog-post-first-section {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    .blog-post-title {
        justify-content: flex-start;
    }
    .blog-subtitle-container {
        display: none;
    }
    .blog-subtitle-small_media {
        display: flex;
        width: 100%;
        height: 30px;
        max-width: 1250px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        margin-top: 41px;
    }
    .blog-post-title h2 {
        font-size: 30px;
    }
    .blog-date-text {
        display: flex;
        flex-direction: column;
    }
    .blog-date-icon {
        width: 30px;
        height: auto;
        flex-shrink: 0;
    }
    .blog-time {
        display: none;
    }
    .blog-time-wrapped {
        display: flex;
        flex-direction: column;
    }
    .blog-date-time p {
        font-size: 10px;
        width: fit-content;
        white-space: wrap;
        text-overflow: ellipsis;
    }
    .blog-social-icon-wrapper {
        width: 200px;
        height: 30px;
        justify-content: flex-end;
    }
    .image-stack {
        max-height: 400px;
        min-height: 347px;
        display: flex;
        position: relative;
        background: url("../images/bahador.jpeg") cover no-repeat;
    }
    .blog-social-icon {
        display: flex;
        width: 30px;
        height: 30px;
        /* padding: 12px; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 64px;
        background: #E0871A;
    }
    .stacked-image-front {
        display: none;
    }
    .blog-post-second-section {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .blog-post-content-container h3 {
        font-size: 30px;
    }
    .blog-post-content-container p {
        font-size: 14px;
    }
    .blog-post-second-img {
        min-height: 300px;
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .blog-post-second-img img {
        min-height: 250px;
    }
    .blog-posts-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media(min-width:440px) and (max-width: 550px) {
    .blog-post-first-section {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    .blog-post-title {
        justify-content: flex-start;
    }
    .blog-subtitle-container {
        display: none;
    }
    .blog-subtitle-small_media {
        display: flex;
        width: 100%;
        height: 30px;
        max-width: 1250px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        margin-top: 41px;
    }
    .blog-post-title h2 {
        font-size: 30px;
    }
    .blog-date-text {
        display: flex;
        flex-direction: column;
    }
    .blog-date-icon {
        width: 30px;
        height: auto;
        flex-shrink: 0;
    }
    .blog-time {
        display: none;
    }
    .blog-time-wrapped {
        display: flex;
        flex-direction: column;
    }
    .blog-date-time p {
        font-size: 10px;
        width: fit-content;
        white-space: wrap;
        text-overflow: ellipsis;
    }
    .blog-social-icon-wrapper {
        width: 200px;
        height: 30px;
        justify-content: flex-end;
    }
    .image-stack {
        max-height: 400px;
        min-height: 347px;
        display: flex;
        position: relative;
        background: url("../images/bahador.jpeg") cover no-repeat;
    }
    .blog-social-icon {
        display: flex;
        width: 30px;
        height: 30px;
        /* padding: 12px; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 64px;
        background: #E0871A;
    }
    .stacked-image-front {
        display: none;
    }
    .blog-post-second-section {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .blog-post-content-container h3 {
        font-size: 30px;
    }
    .blog-post-content-container p {
        font-size: 14px;
    }
    .blog-post-second-img {
        min-height: 300px;
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .blog-post-second-img img {
        min-height: 250px;
    }
    .blog-posts-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media(min-width:550px) and (max-width:680px) {
    .blog-post-title h2 {
        font-size: 30px;
    }
    .blog-post-content-container h3 {
        font-size: 30px;
    }
    .blog-post-content-container p {
        font-size: 14px;
    }
    .blog-posts-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media(min-width:680px) and (max-width:900px) {
    .blog-posts-container {
        grid-template-columns: repeat(2, 1fr);
    }
}