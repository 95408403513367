.buttonWrapper {
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
    width: fit-content;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}

.buttonWrapper button {
    font-size: 18px;
    font-family: Corbel, "Roboto";
}

.buttonWrapper a {
    font-size: 18px;
}

.navButtonWrapper {
    text-decoration: none;
    transition: color 0.3s ease;
    color: #112347;
    cursor: pointer;
}

.navBtnWrapper {
    text-decoration: none;
    transition: color 0.3s ease;
    color: #112347;
    border: none;
    background: transparent;
    cursor: pointer;
}

.navBtnWrapper:hover,
.navBtnWrapper :active,
.navButtonWrapper:hover,
.navButtonWrapper:active {
    text-decoration: none;
    color: #E0871A;
}

.navBtnWrapper:active,
.navButtonWrapper:active {
    border-bottom: 3px solid #E0871A;
}

.selected {
    border-bottom: 3px solid #E0871A;
    color: #000;
    text-align: center;
    font-family: Corbel, "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

@media(max-width:550px) {
    .buttonWrapper button {
        font-size: 14px;
    }
    .buttonWrapper a {
        font-size: 14px;
    }
}