@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.navbarContainer {
    height: 81px;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 101;
    box-shadow: 8px 7px 13px 0px rgba(0, 0, 0, 0.15);
}

.navbarLogo {
    padding-left: 30px;
}

.navMenu {
    display: none;
    position: absolute;
    top: 1.55rem;
    right: 1.2rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
}

.navMenu span {
    height: 0.4rem;
    width: 100%;
    background-color: rgb(6, 6, 6);
    border-radius: 0.2rem;
}

.navbarButtonsWrapper {
    width: 389px;
    height: 24px;
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    justify-content: space-between;
    align-items: center;
}

.navOpenMenu {
    display: none;
}

.navDemoButton {
    padding-right: 10px;
}

@media (max-width:430px) {
    .navDemoButton {
        display: none;
    }
}

@media (max-width: 780px) {
    .navMenu {
        display: flex;
    }
    .navbarContainer {
        align-items: center;
    }
    .navbarButtonsWrapper {
        display: none;
    }
    .navOpenMenu {
        width: 100%;
        height: 200px;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 1rem;
        margin-bottom: -16.73rem;
        margin-left: 0px;
        background-color: white;
        z-index: 9;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.07);
    }
    .navDemoButton {
        margin-right: 4rem;
    }
}

@media (min-width: 780px) and (max-width:1200px) {
    .navbarButtonsWrapper {
        display: flex;
    }
    .navOpenMenu {
        display: none;
    }
}