@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.cardList-wrapper {
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 112px;
}

.cardList-header {
    max-width: 768px;
    min-height: 142px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.cardList-header h1 {
    color: #223476;
    text-align: center;
    font-family: "Titillium Web", "Roboto";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.cardList-header p {
    color: #000;
    text-align: center;
    font-family: "Titillium Web", "Roboto";
    font-size: 25px;
    font-style: normal;
    font-weight: 275;
    line-height: 120%;
    margin: 0px;
}

.cardList-button-wrapper {
    display: flex;
    width: 90%;
    min-height: 24px;
    align-items: center;
    justify-content: center;
    gap: 40px;
    flex-shrink: 0;
    margin-top: 52px;
    margin-bottom: 68px;
}

.cardList-cards-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 48px;
    column-gap: 48px;
    margin: auto;
}

.single-card-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media(max-width:680px) {
    .cardList-wrapper {
        padding-top: 60px;
    }
    .cardList-header p {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: center;
    }
    .cardList-header {
        width: 90%;
    }
    .cardList-header h1 {
        font-size: 30px;
    }
    .cardList-header p {
        font-size: 16px;
    }
    .cardList-button-wrapper {
        max-width: 90%;
        display: flex;
        justify-content: space-evenly;
        column-gap: 15px;
        flex-wrap: wrap;
        row-gap: 20px;
        margin-bottom: 40px;
    }
    .cardList-cards-container {
        margin-top: 0rem;
        margin-bottom: 3rem;
        width: 100%;
    }
}

@media(min-width:680px) and (max-width:780px) {
    .cardList-button-wrapper {
        max-width: 90%;
        display: flex;
        justify-content: space-evenly;
        column-gap: 15px;
        flex-wrap: wrap;
        row-gap: 20px;
        margin-bottom: 40px;
    }
}