.join-beta-top-header {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 112px;
    margin-bottom: 112px;
}

.join-beta-top-header-first {
    width: 90%;
    min-height: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 80px;
}

.join-beta-top-header-img-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

#join-beta-img {
    display: none;
    position: absolute;
    flex-shrink: 0;
    top: 243px;
    left: -20px;
}

#join-beta-img-1 img {
    width: 100%;
    height: auto;
}

#join-beta-img-2 {
    display: none;
}

.join-beta-top-header-img-wrapper h2 {
    color: #223476;
    font-family: "Titillium Web", "Roboto";
    font-size: 3.2675289312457454vw;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-top: 0px;
}

.join-beta-top-header-signup {
    width: 50%;
}

.join-beta-top-header-signup p {
    color: var(--MAIN-BODY-COLOR, #58595B);
    font-family: Corbel, "Roboto";
    font-size: 1.2253233492171545vw;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    align-self: stretch;
}


/* Percentage Header */

.join-beta-top-header-second {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 80px;
}

.Percentage-header {
    height: 108px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 80px;
}

.Percentage-header h2 {
    width: 50%;
    height: 108px;
    display: flex;
    flex-direction: row;
    color: #223476;
    font-family: "Titillium Web", "Roboto";
    font-size: 3.2675289312457454vw;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    margin: 0px;
}

.Percentage-header p {
    width: 50%;
    height: 108px;
    display: flex;
    flex-direction: row;
    color: #58595B;
    font-family: Corbel, "Roboto";
    font-size: 1.2253233492171545vw;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0px;
}


/* Precenteage numbers */

.Percentage-wrapper {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Percentage-item {
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 140px;
    border-left: 10px solid #D3AF37;
    padding-left: 22px;
}

.Percentage-item h2 {
    font-family: Roboto, "Roboto";
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin: 0px;
}

.Percentage-item p {
    margin: 0px;
    color: #1C2D5A;
    font-family: "Titillium Web", "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

@media(max-width:679px) {
    .join-beta-top-header {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    .join-beta-top-header {
        row-gap: 60px;
    }
    .join-beta-top-header-first {
        width: 90%;
        min-height: 248px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* margin-bottom: 0px; */
    }
    .join-beta-top-header-img-wrapper h2 {
        font-size: 30px;
    }
    .join-beta-top-header-signup p {
        font-size: 14px;
    }
    .join-beta-top-header-signup {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .join-beta-top-header-img-wrapper {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    #join-beta-img {
        display: none;
    }
    #join-beta-img-1 {
        display: none;
    }
    #join-beta-img-2 {
        height: 100%;
        display: flex;
        overflow: hidden;
    }
    #join-beta-img-2 img {
        width: 100%;
        height: 100%;
    }
    .join-beta-top-header-second {
        row-gap: 40px;
    }
    .Percentage-header {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 25px;
    }
    .Percentage-header h2 {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        color: #223476;
        font-family: "Titillium Web", "Roboto";
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
    }
    .Percentage-header p {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        color: #58595B;
        font-family: Corbel, "Roboto";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    .Percentage-item {
        height: 80px;
        padding-left: 12px;
        justify-content: space-between;
    }
    .Percentage-item h2 {
        font-size: 30px;
    }
    .Percentage-item p {
        font-size: 12px;
    }
}

@media(min-width: 680px) and (max-width:899px) {
    .join-beta-top-header-first {
        width: 90%;
        min-height: 248px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 112px;
    }
    .join-beta-top-header-img-wrapper h2 {
        font-size: 30px;
    }
    .join-beta-top-header-signup p {
        font-size: 14px;
    }
    .join-beta-top-header-signup {
        width: 70%;
    }
    .join-beta-top-header-img-wrapper {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    #join-beta-img-1 {
        display: none;
    }
    #join-beta-img {
        display: none;
        position: relative;
        left: -60px;
        top: 20px;
    }
    #join-beta-img {
        display: none;
    }
    #join-beta-img-2 {
        height: 100%;
        display: flex;
        overflow: hidden;
    }
    #join-beta-img-2 img {
        width: 100%;
        height: 100%;
    }
    .join-beta-top-header-second {
        row-gap: 60px;
    }
    .Percentage-header {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 25px;
        text-align: center;
    }
    .Percentage-header h2 {
        width: 100%;
        height: 100%;
        font-size: 34px;
    }
    .Percentage-header p {
        width: 100%;
        height: 100%;
        font-size: 16px;
    }
    .Percentage-item {
        height: 100px;
    }
    .Percentage-item h2 {
        font-size: 50px;
    }
    .Percentage-item p {
        font-size: 16px;
    }
}

@media(min-width: 900px) and (max-width:1130px) {
    .join-beta-top-header-second {
        row-gap: 60px;
    }
    .Percentage-item {
        height: 100px;
    }
    .Percentage-item h2 {
        font-size: 50px;
    }
    .Percentage-item p {
        font-size: 16px;
    }
}

@media(min-width: 900px) and (max-width:1140px) {
    .join-beta-top-header {
        row-gap: 112px;
        align-items: center;
        justify-content: center;
    }
    .join-beta-top-header-first {
        width: 90%;
        min-height: 248px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0px;
    }
    #join-beta-img-1 {
        display: none;
    }
    #join-beta-img {
        display: flex;
        position: relative;
        left: 0px;
        top: 0px;
    }
    .join-beta-top-header-img-wrapper {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .join-beta-top-header-signup {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

@media(min-width:1200px) and (max-width:6000px) {
    .join-beta-top-header-img-wrapper h2 {
        font-size: 48px;
    }
    .join-beta-top-header-signup p {
        font-size: 18px;
    }
}