.feedback-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.feedback-popup-content {
    width: 80%;
    max-width: 1165px;
    height: 90%;
    max-height: 666px;
    background: white;
    border-radius: 5px;
    position: relative;
    z-index: 1001;
    border-radius: 25px;
    margin-left: 137px;
    margin-right: 137px;
    position: relative;
    box-shadow: 7px 11px 14px 10px rgba(0, 0, 0, 0.08);
}

.feedback-content-wrapper {
    width: 70%;
    max-width: 375;
    height: 100%;
    z-index: 1002;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.feedback-popup-header {
    margin-top: 56px;
    height: 38.89px;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.feedback-popup-header h4 {
    margin: 0px;
    color: #223476;
    font-family: "Titillium Web", "Roboto";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.feedback-form {
    width: 90%;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 31px;
    margin-top: 31px;
}

.feedback-form label {
    height: 38px;
    color: #BCBEC0;
    font-family: "Titillium Web", "Roboto";
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
}

.feedback-form input {
    height: 38px;
    border: none;
    border-bottom: 1px solid #BCBEC0;
    color: #58595B;
    font-family: Corbel, "Roboto";
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.feedback-form input::selection {
    border: none;
    border-bottom: 1px solid #BCBEC0;
}

.feedback-form-item {
    height: 105px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.feed-back-message {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.feed-back-message-text {
    width: 85%;
    height: 187px;
    border: none;
}

.feed-back-message-text textarea {
    width: 95%;
    height: 150px;
    border: none;
    border-bottom: 1px solid #BCBEC0;
    color: #58595B;
    font-family: Corbel, "Roboto";
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    resize: none;
}

.feed-back-message-button {
    display: flex;
    width: fit-content;
    height: 51px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #E0871A;
    background: #E0871A;
}

.feed-back-message-button button {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
    background: #E0871A;
    font-size: 20px;
    color: #FFF;
    text-align: center;
    font-family: "Titillium Web", "Roboto";
    font-style: normal;
    font-weight: 400;
    padding-right: 24px;
    padding-left: 24px;
    text-align: center;
    cursor: pointer;
    /* line-height: 150%; */
}

textarea:focus,
input:focus {
    outline: none;
}

.contact-info-wrapper {
    border-radius: 0px 25px 25px 0px;
    width: 30%;
    max-width: 375;
    height: 100%;
    background: #25BDBE;
    z-index: 1002;
    position: absolute;
    top: 0px;
    right: 0px;
}

.contact-info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-info-container h4 {
    color: #223476;
    text-align: center;
    font-family: "Titillium Web", "Roboto";
    font-size: 2.053915275994865vw;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-top: 56px;
    margin-bottom: 74px;
}

.contact-info-item {
    width: 90%;
    height: 100%;
    min-height: 47px;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 9.5px;
    margin-bottom: 17px;
}

.contact-info-item p {
    width: 80%;
    color: #FFF;
    font-family: Corbel, "Roboto";
    font-size: 1.1553273427471116vw;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0px;
}

.contact-info-item svg {
    width: 40px;
}

.feedback-popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 1.5em;
    cursor: pointer;
}

@media(max-width:440px) {
    .feedback-popup-content {
        width: 90%;
        max-width: 90%;
        margin-left: 0px;
        margin-right: 0px;
    }
    .feedback-popup-header h4 {
        font-size: 20px;
    }
    .feedback-popup-header svg {
        width: 15%;
        height: 100%;
    }
    .feedback-form label {
        font-size: 18px;
    }
    .feedback-form input {
        font-size: 16px;
    }
    #feedback-address svg {
        width: 20px;
        height: 20px;
    }
    .contact-info-container {
        display: none;
    }
    .contact-info-wrapper {
        width: 15%;
    }
    .feedback-content-wrapper {
        width: 85%;
        max-width: 375;
        height: 100%;
        z-index: 1002;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .feed-back-message-button {
        margin-bottom: 6px;
    }
    .feed-back-message-button button {
        font-size: 16px;
        height: 40px;
    }
}

@media(min-width:440px) and (max-width: 679px) {
    .feedback-popup-content {
        width: 90%;
        max-width: 90%;
        margin-left: 0px;
        margin-right: 0px;
    }
    .feedback-popup-header h4 {
        font-size: 20px;
    }
    .feedback-popup-header svg {
        width: 15%;
        height: 100%;
    }
    .feedback-form label {
        font-size: 18px;
    }
    .feedback-form input {
        font-size: 16px;
    }
    .contact-info-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 0px;
        margin-bottom: 0px;
        row-gap: 9.5px;
        text-align: center;
    }
    #feedback-address svg {
        width: 20px;
        height: 20px;
    }
    .contact-info-item svg {
        width: 20px;
        height: 20px;
    }
    .contact-info-item p {
        margin: 0px;
        font-size: 10px;
        text-overflow: wrap;
    }
    .contact-info-container h4 {
        font-size: 18px;
    }
    .feed-back-message-button {
        margin-bottom: 6px;
    }
    .feed-back-message-button button {
        font-size: 18px;
    }
}

@media(min-width:680px) and (max-width:900px) {
    .feedback-popup-header h4 {
        font-size: 20px;
    }
    .feedback-popup-header svg {
        width: 12%;
    }
    .feedback-form label {
        font-size: 18px;
    }
    .feedback-form input {
        font-size: 16px;
    }
    .contact-info-item {
        padding-left: 5px;
        margin-bottom: 5px;
    }
    .contact-info-item p {
        margin: 0px;
    }
    .feed-back-message-button {
        margin-bottom: 6px;
    }
}

@media(min-width: 900px) and (max-width: 1000px) {
    .contact-info-item {
        padding-left: 28px;
        margin-bottom: 10px;
    }
    .contact-info-container h4 {
        font-size: 20px;
    }
    .contact-info-item p {
        font-size: 12px;
    }
}

@media(min-width:1400px) and (max-width:5000px) {
    .contact-info-container h4 {
        font-size: 32px;
    }
    .contact-info-item p {
        font-size: 18px;
    }
}