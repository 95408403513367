.rowWrapper {
    max-width: 100%;
    height: 100%;
    column-gap: 80px;
    margin-left: 97px;
    margin-right: 97px;
}


/* This is wrapper for row with image in right side */

.rightImgRow {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}


/* This is wrapper for row with image in leftt side */

.leftImgRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.row-img {
    max-width: 640px;
    max-height: 100%;
    border-radius: 25px;
}

.row-img img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 25px;
}


/* Contents */
.right-row-content-wrapper-horizontal {
    max-width: 591px;
    min-width: 320px;
    height: 100%;
    display: flex;
    flex-direction: column; /* Changed from row to column */
    justify-content: left;
    align-items: center;
    row-gap: 32px; /* Changed from column-gap to row-gap */
    /* column-gap: 32px; Removed this line */
}

.right-row-content-wrapper {
    max-width: 591px;
    min-width: 320px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 32px;
}

.left-row-content-wrapper {
    max-width: 591px;
    min-width: 320px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.left-row-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.left-row-content h3 {
    color: #223476;
    font-family: "Titillium Web", "Roboto";
    font-size: 3.2675289312457454vw;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 24px;
    margin-top: 10px;
}

.left-row-content p {
    color: #58595b;
    font-family: Corbel, "Roboto";
    font-size: 1.2253233492171545vw;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.right-row-content-first {
    width: 100%;
}

.right-row-content-first h3 {
    color: #223476;
    font-family: "Titillium Web", "Roboto";
    font-size: 3.2675289312457454vw;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 24px;
}

.right-row-content-first p {
    color: #58595b;
    font-family: Corbel, "Roboto";
    font-size: 1.2253233492171545vw;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.right-row-content-second {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: 24px;
    padding: 8px 0px 8px 0px;
}

.right-row-content-second-horizontal  {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    column-gap: 24px;
    padding: 8px 0px 8px 0px;
}

.content-with-image-and-title{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 24px;
    padding: 8px 0px 8px 0px;
}
.content-with-image-and-title h4{
    color: #d3af37;
    font-family: "Titillium Web", "Roboto";
    font-size: 1.3614703880190606vw;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-top: 16px;
    margin-bottom: 16px;
}

.right-row-content-second h4 {
    color: #d3af37;
    font-family: "Titillium Web", "Roboto";
    font-size: 1.3614703880190606vw;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-top: 16px;
    margin-bottom: 16px;
}

.right-row-content-second p {
    color: #58595b;
    font-family: Corbel, "Roboto";
    font-size: 1.2253233492171545vw;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.vector-list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.vector-list-item {
    min-height: auto;
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    justify-content: center;
    align-items: center;
}

.vector-list-item p {
    margin: 0px;
    color: #58595b;
    font-family: Corbel, "Roboto";
    font-size: 1.0891763104152485vw;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    min-height: auto;
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    justify-content: center;
    align-items: center;
}

.left-row-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 40px;
}

#tiny-vector {}

@media (max-width: 680px) {
    /* .rowWrapper {
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 55px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .rightImgRow {
        display: flex;
        flex-direction: column;
        row-gap: 55px;
    } */
    .rowWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 55px;
    }
    .rightImgRow {
        display: flex;
        flex-direction: column;
    }
    .row-img {
        width: 100%;
        max-height: 30rem;
    }
    .right-row-content-wrapper {
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .left-row-content-wrapper {
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .left-row-content h3 {
        font-size: 30px;
    }
    .right-row-content-wrapper {
        height: 100%;
    }
    .left-row-content h3 {
        font-size: 30px;
    }
    .left-row-content p {
        font-size: 14px;
    }
    .vector-list-item p {
        font-size: 14px;
    }
    .right-row-content-first h3 {
        font-size: 30px;
    }
    .right-row-content-first p {
        font-size: 14px;
    }
    .right-row-content-second h4 {
        font-size: 16px;
    }
    .right-row-content-second p {
        font-size: 14px;
    }
}

@media (min-width: 680px) and (max-width: 899px) {
    .rowWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 55px;
    }
    .rightImgRow {
        display: flex;
        flex-direction: column;
    }
    .right-row-content-wrapper {
        height: 100%;
    }
    .left-row-content h3 {
        font-size: 30px;
    }
    .left-row-content p {
        font-size: 18px;
    }
    .vector-list-item p {
        font-size: 16px;
    }
    .right-row-content-first h3 {
        font-size: 30px;
    }
    .right-row-content-first p {
        font-size: 18px;
    }
    .right-row-content-second h4 {
        font-size: 20px;
    }
    .right-row-content-second p {
        font-size: 18px;
    }
}

@media (min-width: 900px) and (max-width: 1200px) {
    .right-row-content-wrapper {
        display: flex;
        justify-content: flex-start;
    }
    .left-row-content h3 {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}