.blogHeaderWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 112px;
    padding-bottom: 112px;
}

.blogHeaderContainer {
    width: 90%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blogHeaderPath {
    width: 100%;
    max-width: 1250px;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
}

.blogHeaderPath h6 {
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0px;
}

.blogHeaderTitle {
    width: 100%;
    max-width: 1250px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.blogHeaderTitle h3 {
    color: #223476;
    font-family: "Titillium Web";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0px;
    margin-bottom: 41px;
}

.blogHeaderMedia {
    width: 100%;
    max-width: 1250px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 86px;
}

.blogHeaderBookIcon {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
}

.blogHeaderMediaIcons {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
}

.blogHeaderBookIcon p {
    color: #1C2D5A;
    font-family: "Titillium Web";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.blogIcons {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 2px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 64px;
    background: #25BDBE;
}

.blogHeaderImg {
    width: 100%;
    height: 547px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blogHeaderImg img {
    width: 100%;
    max-width: 1250px;
    height: 100%;
    border-radius: 15px;
}

@media(max-width:400px) {
    .blogHeaderWrapper {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .blogHeaderTitle h3 {
        font-size: 30px;
        margin-bottom: 30px;
    }
    .blogHeaderMedia {
        margin-bottom: 43px;
    }
    .blogIcons {
        width: 20px;
        height: 20px;
        padding: 5px;
    }
    .blogIcons svg {
        width: 25px;
        height: 25px;
        padding: 1px;
    }
    .blogHeaderImg {
        width: 100%;
        height: 100%;
        max-height: 400px;
    }
    .blogHeaderImg img {
        height: auto;
    }
    .blogHeaderBookIcon p {
        font-size: 12px;
    }
}

@media(min-width:400px) and (max-width:680px) {
    .blogHeaderWrapper {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .blogHeaderTitle h3 {
        font-size: 30px;
        margin-bottom: 30px;
    }
    .blogIcons {
        width: 25px;
        height: 25px;
        padding: 5px;
    }
    .blogIcons svg {
        width: 25px;
        height: 25px;
        padding: 3px;
    }
    .blogHeaderImg {
        width: 100%;
        height: 100%;
        max-height: 400px;
    }
    .blogHeaderImg img {
        height: auto;
    }
    .blogHeaderBookIcon p {
        font-size: 12px;
    }
}