@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400;500;600;700;800;900&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.homeContainer {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wavingHand {
    position: absolute;
    top: 82px;
    height: 50px;
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 14px;
}

.wavingHand p {
    color: #58595B;
    font-family: "Titillium Web", "Roboto";
    font-size: 30px;
    font-style: normal;
    font-weight: 275;
    line-height: 120%;
    margin: 0px;
}

.wavingHand img {
    width: 26px;
    height: 26px;
}

.homeHeaderWavingHand {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    margin-bottom: -60px;
}

.homeHeaderWavingHand p {
    font-size: 25px;
    font-weight: 200;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.stepHomeWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    padding-top: 112px;
    padding-bottom: 112px;
}

.stepHomeHeader {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.stepHomeWrapperHead {
    width: 90%;
    min-height: 36px;
}

.stepHomeWrapperHead p {
    font-size: 30px;
    font-weight: 275;
    letter-spacing: 0em;
    text-align: left;
    color: #58595B;
    margin: 0px;
    /* margin-top: 112px; */
}

.stepHomeHeaderContent {}

.stepHomeHeader h3 {
    font-size: 48px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(34, 52, 118, 1);
    margin-top: 0px;
}

.stepHomeContainer {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.stepHomeSteps {
    width: 50%;
    display: flex;
    flex-direction: row;
}

.stepHomeStepIcons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.stepHomeStepsContent {
    display: flex;
    flex-direction: column;
    row-gap: 77px;
    margin-left: 3rem;
    margin-top: 51px;
}

.stepWrapper {
    display: flex;
    max-width: 528px;
    height: 98px;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    flex-shrink: 0;
}

.stepWrapper h4 {
    color: var(--Main-color-2, #D3AF37);
    font-family: "Titillium Web", "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin: 0px;
}

.stepWrapper p {
    color: #58595B;
    font-family: Corbel, "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0px;
}

@media (max-width: 600px) {
    .wavingHand {
        position: absolute;
        top: 60px;
        height: 50px;
        margin-top: 60px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 14px;
    }
    .stepHomeImg svg {
        width: 90%;
        height: auto;
    }
    .stepHomeWrapper {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .stepHomeContainer {
        flex-direction: column;
    }
    .wavingHand p {
        font-size: 20px;
    }
    .stepHomeHeader {
        width: 100%;
    }
    .stepHomeWrapperHead p {
        font-size: 20px;
    }
    .stepHomeHeader h3 {
        font-size: 30px;
    }
    .stepHomeHeaderContent {
        width: 100%;
    }
    .stepHomeSteps {
        width: 100%;
        margin-top: 40px;
    }
    .stepHomeStepIcons {
        margin-top: 0px;
    }
    .stepHomeStepsContent {
        margin-top: 10px;
        /* margin-bottom: 55px; */
        row-gap: 75px;
    }
    .stepWrapper h4 {
        font-size: 16px;
        font-weight: 700;
        text-align: start;
    }
    .stepWrapper p {
        font-size: 14px;
        font-weight: 400;
    }
}

@media screen and (max-width: 780px) {
    .wavingHand {
        position: absolute;
        top: 60px;
        height: 50px;
        margin-top: 60px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 14px;
    }
    .stepHomeContainer {
        flex-direction: column;
    }
    .stepHomeHeader {
        width: 100%;
    }
    .stepHomeHeaderContent {
        width: 100%;
    }
    .stepHomeHeader h3 {
        text-align: start;
    }
    .stepHomeSteps {
        width: 100%;
        margin-top: 40px;
    }
    .stepHomeStepIcons {
        margin-top: 0px;
    }
    .stepHomeStepsContent {
        margin-top: 18px;
        /* margin-bottom: 35px; */
    }
    .stepWrapper h4 {
        font-size: 16px;
        font-weight: 700;
        text-align: start;
    }
}