.serviceCardWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    align-items: center;
    max-width: 405.33px;
    min-width: 308px;
    min-height: 380px;
    transition: .3s all ease-in-out;
}

.serviceCardImg {
    width: 100%;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.serviceCardImg img {
    max-width: 100%;
    max-height: 100%;
}

.serviceCardContent {
    width: 90%;
    min-height: 98px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.serviceCardContent h4 {
    color: #1C2D5A;
    text-align: center;
    font-family: "Titillium Web", "Roboto";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    align-self: stretch;
    margin: 0px;
}

.serviceCardContent p {
    color: #58595B;
    text-align: left;
    font-family: "Titillium Web", "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    align-self: stretch;
    margin: 0px;
}

.serviceCardContent .more {
    color: #05b6b6;
    cursor: pointer;
}

.collapsed {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.expanded {
    white-space: normal;
}

.serviceCardContent .collapsed {
    max-height: 3em;
    overflow: hidden;
    position: relative;
    transition: max-height 0.3s ease;
}

.serviceCardContent .expanded {
    max-height: 20em;
}

.serviceCardContent button {
    background: none;
    border: none;
    color: rgb(13, 140, 172);
    cursor: pointer;
    padding: 0;
    margin-top: 0.5em;
}

@media(max-width:250px) {
    .serviceCardWrapper {
        row-gap: 22px;
        max-width: 250px;
        min-width: 90%;
        min-height: 100%;
    }
}

@media(max-width:780px) {
    .serviceCardWrapper {
        row-gap: 22px;
        max-width: 250px;
        min-width: 230px;
        min-height: 100%;
    }
}

@media(min-width: 780px) and (max-width: 900px) {
    .serviceCardWrapper {
        row-gap: 22px;
        max-width: 250px;
        min-width: 230px;
        min-height: 426px;
    }
}

@media(min-width:900px) and (max-width: 1000px) {
    .serviceCardWrapper {
        row-gap: 22px;
        max-width: 300px;
        min-width: 250px;
        min-height: 426px;
    }
}

@media(min-width:1000px) and (max-width: 1200px) {
    .serviceCardWrapper {
        row-gap: 32px;
        max-width: 350px;
        min-width: 300px;
        min-height: 426px;
    }
}