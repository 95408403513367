.shared-blog-content-wrapper {
    width: 100%;
    height: 100%;
    background: #E0871A;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.shared-blog-content-container {
    width: 90%;
    max-width: 1250px;
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: flex-start; */
    text-align: left;
    padding-top: 90px;
}

.shared-blog-content-container h3 {
    width: 100%;
    max-width: 1250px;
    color: #223476;
    font-family: "Titillium Web";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-align: left;
    /* margin-top: 90px; */
}

.shared-blog-content-container h4 {
    width: 100%;
    max-width: 1250px;
    color: #223476;
    font-family: "Titillium Web";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-align: left;
    /* margin-top: 90px; */
}

.blog-subtitle {
    width: 100%;
    max-width: 1250px;
    color: #F8FAFF;
    font-family: Corbel, Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-align: left;
}

.shared-blog-content-container p {
    width: 100%;
    max-width: 1250px;
    color: #F8FAFF;
    font-family: Corbel, Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
}

.shared-blog-content-container ol {
    width: 100%;
    max-width: 1250px;
    color: #F8FAFF;
    font-family: Corbel, Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-align: left;
}

.shared-blog-content-container a {
    font-size: 18px;
    color: blue;
}

.shared-blog-content-container a:visited,
.shared-blog-content-container a:active,
.shared-blog-content-container a:hover {
    font-size: 18px;
    color: blue;
}

.blog-post-img-two {
    width: 100%;
    max-width: 1250px;
    height: 500px;
}

.blog-post-img-two img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

@media(max-width:680px) {
    .shared-blog-content-container {
        padding-top: 45px;
    }
    .shared-blog-content-container h3 {
        font-size: 30px;
    }
    .shared-blog-content-container p {
        font-size: 14px;
    }
    .blog-post-img-two {
        width: 100%;
        height: 100%;
    }
    .blog-post-img-two img {
        width: 100%;
        height: auto;
        border-radius: 15px;
    }
}