@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.footerContainer {
    width: 100%;
    margin-top: auto;
    min-height: 377px;
    background-color: #1C2D5A;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.footerFirstSection {
    max-width: 441px;
    min-height: 21px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1.5rem;
}

.footerSocialWrapper {
    width: 90%;
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: flex-end;
}

.footerSocialMedia {
    width: 252px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 32px;
}

.footerLogo {
    width: 99px;
    height: 87px;
    position: absolute;
    left: 0;
    margin-left: 64px;
    margin-top: -101px;
    margin-bottom: 103px;
}

.footerLogoTwo {
    display: none;
}

.footerSecondSection {
    width: 90%;
    min-height: 54px;
    border-top: 1px solid white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.footerSecondSection p {
    margin-bottom: 0px;
    color: white;
}

.footerFirstSectionButtons {
    max-width: 441px;
    height: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 32px;
    justify-content: space-evenly;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
}

.footerSecondSectionButtons {
    max-width: 400px;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    justify-content: space-evenly;
    color: black;
    cursor: pointer;
}

.footerSecondSectionButtons a,
a:visited,
a:hover {
    color: white;
}

@media(max-width:450px) {
    .footerContainer {
        min-height: 347px;
    }
    .footerFirstSectionButtons {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 24px;
        margin-bottom: 24px;
        row-gap: 24px;
    }
    .footerSecondSection {
        padding-top: 12px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        align-items: center;
        row-gap: 20px;
    }
    .footerSecondSection p {
        color: #FFF;
        font-family: "Titillium Web", "Roboto";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-top: 66px;
        margin-bottom: 30px;
    }
    .footerSecondSectionButtons {
        display: flex;
        font-family: Corbel, "Roboto";
        max-width: 360px;
        flex-wrap: wrap;
        row-gap: 24px;
        font-size: 12px;
    }
    .footerLogo {
        display: none;
    }
    .footerLogoTwo {
        margin-top: 24px;
        margin-bottom: 24px;
        display: flex;
    }
    .footerSocialWrapper {
        width: 90%;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
    }
}

@media(min-width:451px) and (max-width: 720px) {
    .footerContainer {
        min-height: 347px;
    }
    .footerFirstSectionButtons {
        display: flex;
        flex-wrap: wrap;
        /* row-gap: 20px; */
        margin-bottom: 24px;
    }
    .footerSecondSection {
        padding-top: 12px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        align-items: center;
        row-gap: 20px;
    }
    .footerSecondSection p {
        color: #FFF;
        font-family: "Titillium Web", "Roboto";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-top: 66px;
        margin-bottom: 30px;
    }
    .footerSecondSectionButtons {
        display: flex;
        flex-wrap: wrap;
        row-gap: 24px;
        font-size: 14px;
    }
    .footerLogo {
        display: none;
    }
    .footerLogoTwo {
        margin-top: 24px;
        margin-bottom: 24px;
        display: flex;
    }
    .footerSocialWrapper {
        width: 90%;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
    }
}