    .scrollToTopButton {
        position: fixed;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1000;
        background: transparent;
        border: none;
        fill: var(--Sharp-Complementary-2, #25BDBE);
    }
    
    .scrollToTopButton.visible {
        display: block;
    }
    
    .scrollToTopSVG {
        width: 83px;
        height: 83px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .scrollToTopSVG svg {
        width: 83px;
        height: 83px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        fill: var(--Sharp-Complementary-2, #25BDBE);
        filter: drop-shadow(0px 3px 6px rgba(17, 35, 71, 0.25));
        /* filter: drop-shadow(0px 5px 9px rgba(17, 35, 71, 0.50)); */
    }
    /* .scrollToTopSVGCircle {
        position: relative;
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        z-index: 101;
        fill: var(--Sharp-Complementary-3, #E0871A);
        filter: drop-shadow(0px 5px 9px rgba(17, 35, 71, 0.50));
    }
    
    .scrollToTopSVGArrow {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 102;
        right: -14px;
        top: 10px;
        flex-shrink: 0;
    }
    
    .scrollToTopIcon {
        display: none;
    } */
    
    @media(max-width:680px) {
        .scrollToTopButton {
            transform: translateY(-50%) scale(0.75);
            top: 50%;
            fill: var(--Sharp-Complementary-3, #E0871A);
        }
        .scrollToTopSVG svg {
            width: 63px;
            height: 63px;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            fill: var(--Sharp-Complementary-2, #25BDBE);
        }
        /* .scrollToTopIcon {
            display: flex;
            width: 50px;
            height: 50px;
            fill: var(--Sharp-Complementary-3, #E0871A);
            filter: drop-shadow(0px 5px 9px rgba(17, 35, 71, 0.50));
        } */
    }