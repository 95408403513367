.homeHeaderWrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 112px;
}

.homeHeaderContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homeHeader {
    max-width: 939px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 40px;
}

.homeHeader h1 {
    color: #1C2D5A;
    text-align: center;
    font-family: "Titillium Web", "Roboto";
    font-size: 3.38vw;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    margin: 0px;
}

.homeHeader p {
    color: #58595B;
    font-family: "Titillium Web", "Roboto";
    font-size: 1.7018379850238257vw;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0px;
    text-align: center;
}

.highlight {
    font-weight: bold;
    color: #58595B;
    font-weight: 700;
}

.headerLand-component {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    /* margin-bottom: 30px; */
}

.homeHeaderContainer img {
    width: 100%;
    height: auto;
}

.homeHeaderContainer video {
    width: 80%;
    height: auto;
}

@media(max-width:680px) {
    .homeHeaderWrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 60px;
    }
    .homeHeader {
        max-width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        row-gap: 20px;
    }
    .homeHeaderWrapper h1 {
        font-size: 30px;
    }
    .homeHeaderWrapper p {
        max-width: 90%;
        font-size: 18px;
    }
    .homeHeaderContainer video {
        width: 90%;
        height: auto;
    }
}

@media(min-width:680px) and (max-width:1100px) {
    .homeHeader {
        max-width: 740px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .homeHeader {
        max-width: 90%;
    }
    .homeHeader h1 {
        color: #1C2D5A;
        text-align: center;
        font-family: "Titillium Web", "Roboto";
        font-size: 38px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        margin: 0px;
    }
    .homeHeader p {
        color: #58595B;
        font-family: "Titillium Web", "Roboto";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin: 0px;
        text-align: center;
    }
}

@media(min-width:1100px) and (max-width:5000px) {
    .homeHeaderWrapper h1 {
        font-size: 56px;
    }
    .homeHeaderWrapper p {
        font-size: 25px;
    }
}