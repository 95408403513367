.slider-main-container {
    width: 100%;
    min-height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* row-gap: 50px; */
}

.slider-header-container {
    width: 90%;
    height: 234px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 10px;
    margin-right: 10px;
    /* margin-bottom: 50px; */
    margin-top: 93px;
}

.slider-header-top h3 {
    color: #58595B;
    text-align: start;
    font-family: "Titillium Web", "Roboto";
    font-size: 30px;
    font-style: normal;
    font-weight: 275;
    line-height: 120%;
    margin-bottom: 0px;
    margin-top: 0px;
}

.slider-header-second-wrapper {
    min-height: 194px;
}

.slider-header-second-one {
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: left;
}

.slider-header-second-one h3 {
    color: #223476;
    font-family: "Titillium Web", "Roboto";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-top: 0px;
    margin-bottom: 17px;
}

.slider-header-second-two {
    height: 73px;
    display: flex;
    align-items: center;
    border-left: 10px solid #D3AF37;
    margin-top: 17px;
}

.slider-header-second-two p {
    color: #58595B;
    font-family: Corbel, "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding-left: 30px;
}

.temp-future-apps {
    width: 95%;
    max-width: 1312px;
    min-height: 400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    column-gap: 48px;
    margin-bottom: 2rem;
}

.slider-container {
    width: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    position: relative;
    padding-bottom: 114px;
    /* padding-top: 20px; */
}

.left-slide,
.right-slide {
    width: 10%;
    max-width: 98px;
    height: 400px;
    border: 2px;
}

.left-slide {
    margin-right: -30px;
    border-radius: 15px 0px 0px 15px;
    border-left: 2px solid#D3AF37;
    border-top: 2px solid#D3AF37;
    border-right: none;
    border-bottom: 2px solid#D3AF37;
}

.right-slide {
    margin-left: -30px;
    border-radius: 0px 15px 15px 0px;
    border-left: none;
    border-top: 2px solid#D3AF37;
    border-right: 2px solid#D3AF37;
    border-bottom: 2px solid#D3AF37;
}

.activeSlide {
    width: 50%;
    max-width: 791px;
    min-width: 598px;
    height: 400px;
    transition: transform 0.5s ease-in-out;
    cursor: pointer;
    position: relative;
    border-radius: 0px 15px 15px 0px;
    display: flex;
    flex-direction: row;
    z-index: 5;
    margin-right: -10px;
    border: 2px solid#D3AF37;
}

.slide-number {
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    top: 20px;
    left: 10px;
    color: black;
    z-index: 10;
    top: 0;
    left: 0;
    font-size: 24px;
}

.slide-number h3 {
    font-size: 24px;
}

.activeSlide img {
    border-radius: 50px;
}

.slide-details img {
    width: 423px;
    height: 324px;
    flex-shrink: 0;
    bottom: 0;
}

.left-slide .slide-side-number {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    margin-left: 15%;
    font-size: 24px;
    border: none;
}

.right-slide .slide-side-number {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
    margin-left: -15%;
    font-size: 24px;
    border: none;
}

.slide-side-number-top h3 {
    font-size: 24px;
}

.slide-side-number-below {
    writing-mode: vertical-rl;
    padding-bottom: 10px;
    top: 592px;
    left: 49px;
    transform: rotate(180deg);
    padding-top: 15px;
}

.left-slide .slide-side-number-below h3 {
    font-size: 24px;
    margin-block-start: 0px;
}

.right-slide .slide-side-number-below h3 {
    font-size: 24px;
    margin-block-start: 0px;
    margin-block-end: -7px;
}

.slide-number-below {
    writing-mode: vertical-rl;
    padding-top: 15px;
    transform: rotate(180deg);
}

.activeSlide .slide-number {
    background-color: #D3AF37;
}

.activeSlide .slide-details {
    min-width: 598;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
}

.slide-details-bottom-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.activeSlide .slide-details h3 {
    margin-top: 2px;
    margin-left: 2rem;
    margin-bottom: 0px;
    font-size: 40px;
    font-weight: 700;
}

.activeSlide .slide-details p {
    margin-top: 0px;
    margin-left: 2rem;
    font-size: 16px;
    color: #58595B;
}

@media (max-width: 550px) {
    .temp-future-apps {
        display: flex;
        flex-wrap: wrap;
    }
    .slider-main-container {
        row-gap: 1px;
    }
    .slider-header-top h3 {
        font-size: 20px;
    }
    .slider-header-second-one h3 {
        font-size: 30px;
    }
    .slider-header-second-two p {
        font-size: 14px;
    }
    .slider-container {
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow: visible;
        /* padding-top: 50px; */
    }
    .left-slide,
    .right-slide,
    .activeSlide {
        width: 80%;
        max-width: none;
        margin-right: 0;
        margin-left: 0;
        border-radius: 5px;
    }
    .left-slide,
    .right-slide {
        height: 60px;
    }
    .activeSlide {
        min-width: 50%;
        height: 100%;
        flex-direction: column;
        border-radius: 0px 0px 15px 15px;
        margin-bottom: -18px;
    }
    .slide-details-bottom-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .activeSlide .slide-number {
        height: 60px;
        width: 100%;
        flex-direction: row;
    }
    .activeSlide .slide-details {
        width: 100%;
        margin-left: 0px;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
    }
    .slide-details img {
        width: 100%;
        margin-left: 0px;
        align-items: center;
        overflow: hidden;
    }
    .slide-number-top {
        margin-left: 10px;
    }
    .slide-number-below {
        writing-mode: horizontal-tb;
        padding-top: 15px;
        transform: rotate(0deg);
        margin-right: 20px;
    }
    .slide-number-below h3 {
        margin-block-start: 0px;
    }
    .left-slide {
        margin-right: 0px;
        margin-bottom: -10px;
        border-radius: 15px 15px 0px 0px;
        border-left: 2px solid#D3AF37;
        border-top: 2px solid#D3AF37;
        border-right: 2px solid#D3AF37;
        border-bottom: none;
    }
    .left-slide .slide-side-number {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 0px;
    }
    .left-slide .slide-side-number-below {
        writing-mode: horizontal-tb;
        padding-top: 15px;
        transform: rotate(0deg);
        margin-right: 20px;
        margin-top: 5px;
    }
    .left-slide .slide-side-number-top {
        margin-left: 10px;
    }
    .right-slide {
        margin-right: 0px;
        margin-bottom: -10px;
        border-radius: 0px 0px 15px 15px;
        border-top: none;
        border-bottom: 2px solid #D3AF37;
        border-left: 2px solid #D3AF37;
        border-radius: 2px solid #D3AF37;
    }
    .right-slide .slide-side-number {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 0px;
    }
    .right-slide .slide-side-number-below {
        writing-mode: horizontal-tb;
        padding-top: 5px;
        transform: rotate(0deg);
        margin-right: 20px;
        margin-top: 5px;
    }
    .right-slide .slide-side-number-top {
        margin-left: 10px;
        margin-top: 15px;
    }
    .activeSlide .slide-number-top h3 {
        font-size: 16px;
    }
    .activeSlide .slide-number-below h3 {
        font-size: 16px;
    }
    .left-slide .slide-side-number-top h3,
    .right-slide .slide-side-number-top h3 {
        font-size: 16px;
    }
    .left-slide .slide-side-number-below h3,
    .right-slide .slide-side-number-below h3 {
        font-size: 16px;
    }
    .activeSlide .slide-details h3 {
        font-size: 26px;
        font-weight: 700;
    }
    .activeSlide .slide-details p {
        font-size: 14px;
        font-weight: 400;
    }
    .slide-details img {
        width: 356px;
        height: 274px;
        margin-left: 0px;
        align-items: center;
        overflow: hidden;
        margin: auto;
    }
}

@media (min-width:550px) and (max-width: 780px) {
    .temp-future-apps {
        display: flex;
        flex-wrap: wrap;
    }
    .slider-main-container {
        row-gap: 50px;
    }
    .slider-container {
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow: visible;
    }
    .left-slide,
    .right-slide,
    .activeSlide {
        width: 80%;
        max-width: none;
        margin-right: 0;
        margin-left: 0;
        border-radius: 5px;
    }
    .left-slide,
    .right-slide {
        height: 60px;
    }
    .activeSlide {
        min-width: 50%;
        height: 100%;
        flex-direction: column;
        border-radius: 0px 0px 15px 15px;
        margin-bottom: -18px;
    }
    .activeSlide .slide-number {
        height: 60px;
        width: 100%;
        flex-direction: row;
    }
    .activeSlide .slide-details {
        width: 100%;
        height: 100%;
        margin-left: 0px;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
    }
    .slide-details-bottom-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .slide-details img {
        width: 100%;
        margin-left: 0px;
        align-items: center;
        overflow: hidden;
    }
    .slide-number-top {
        margin-left: 10px;
    }
    .slide-number-below {
        writing-mode: horizontal-tb;
        padding-top: 15px;
        transform: rotate(0deg);
        margin-right: 20px;
    }
    .slide-number-below h3 {
        margin-block-start: 0px;
    }
    .left-slide {
        margin-right: 0px;
        margin-bottom: -10px;
        border-radius: 15px 15px 0px 0px;
        border-left: 2px solid#D3AF37;
        border-top: 2px solid#D3AF37;
        border-right: 2px solid#D3AF37;
        border-bottom: none;
    }
    .left-slide .slide-side-number {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 0px;
    }
    .left-slide .slide-side-number-below {
        writing-mode: horizontal-tb;
        padding-top: 15px;
        transform: rotate(0deg);
        margin-right: 20px;
        margin-top: 5px;
    }
    .left-slide .slide-side-number-top {
        margin-left: 10px;
    }
    .right-slide {
        margin-right: 0px;
        margin-bottom: -10px;
        border-radius: 0px 0px 15px 15px;
        border-top: none;
        border-bottom: 2px solid #D3AF37;
        border-left: 2px solid #D3AF37;
        border-radius: 2px solid #D3AF37;
    }
    .right-slide .slide-side-number {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 0px;
    }
    .right-slide .slide-side-number-below {
        writing-mode: horizontal-tb;
        padding-top: 5px;
        transform: rotate(0deg);
        margin-right: 20px;
        margin-top: 5px;
    }
    .right-slide .slide-side-number-top {
        margin-left: 10px;
        margin-top: 15px;
    }
    .slide-number-top h3 {
        font-size: 22px;
    }
    .slide-number-below h3 {
        font-size: 22px;
    }
    .slide-side-number-top h3 {
        font-size: 22px;
    }
    .slide-side-number-below h3 {
        font-size: 22px;
    }
    .slide-details h3 {
        font-size: 30px;
        font-weight: 700;
    }
    .slide-details {}
    .slide-details img {
        width: 356px;
        height: 274px;
        margin-left: 0px;
        align-items: center;
        overflow: hidden;
        margin: auto;
    }
}

@media(min-width:780px) and (max-width:900px) {
    .slide-number-top h3 {
        font-size: 22px;
    }
    .slide-number-below h3 {
        font-size: 22px;
    }
    .slide-side-number-top h3 {
        font-size: 22px;
    }
    .slide-side-number-below h3 {
        font-size: 22px;
    }
    .slide-details h3 {
        font-size: 30px;
        font-weight: 700;
    }
    .slide-details img {
        width: 356px;
        height: 274px;
        margin-left: 0px;
        align-items: center;
        overflow: hidden;
        margin: auto;
    }
}

@media(min-width: 900px) and (max-width:1200px) {
    .slide-number-top h3 {
        font-size: 24px;
    }
    .slide-number-below h3 {
        font-size: 24px;
    }
    .slide-side-number-top h3 {
        font-size: 24px;
    }
    .slide-side-number-below h3 {
        font-size: 24px;
    }
    .slide-details h3 {
        font-size: 34px;
        font-weight: 700;
    }
    .slide-details img {
        width: 356px;
        height: 274px;
        margin-left: 0px;
        align-items: center;
        overflow: hidden;
        margin: auto;
    }
}

@media(min-width: 1200px) and (max-width:1300px) {
    .slide-details img {
        width: 356px;
        height: 274px;
        margin-left: 0px;
        align-items: center;
        overflow: hidden;
        margin: auto;
    }
}