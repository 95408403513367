/* FAQ component styles */

.questions-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.faq-list {
    width: 90%;
    margin: 0;
    padding: 0;
    list-style: none;
}

.faq-item {
    border-bottom: 1px solid #58595B;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
}

.faq-question {
    height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.faq-question h6 {
    margin: 0;
    color: #58595B;
    font-family: "Titillium Web", "Roboto";
    font-size: 20px;
    font-weight: 700;
    line-height: 150%;
    transition: color 0.3s ease;
}

.faq-item.open .faq-question h6 {
    color: #D3AF37;
    font-family: "Titillium Web", "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.faq-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    margin-right: 16px;
    transition: transform 0.3s ease;
}

.faq-item.open .faq-toggle {
    transform: rotate(180deg);
}

.faq-answer {
    padding-top: 20px;
    display: none;
    color: #58595B;
    font-family: Corbel, "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-left: 45px;
}

.faq-item.open .faq-answer {
    display: block;
}


/* Contact us styles */

.contact-us-wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 83px;
    margin-left: 32px;
}

.contact-us-wrapper h3 {
    margin: 0;
    color: #1C2D5A;
    font-family: "Titillium Web";
    font-size: 32px;
    font-weight: 700;
    line-height: 130%;
}

.contact-us-wrapper p {
    margin: 0;
    color: #58595B;
    font-family: Corbel, "Roboto";
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    margin-top: 16px;
    margin-bottom: 24px;
}


/* Responsive adjustments */

@media (max-width: 680px) {
    .contact-us-wrapper h3 {
        font-size: 20px;
    }
    .contact-us-wrapper p {
        font-size: 14px;
    }
    .faq-question h6 {
        font-size: 16px;
        /* Smaller font size for mobile */
    }
    .faq-answer {
        font-size: 14px;
    }
    .faq-item.open .faq-question h6 {
        font-size: 16px;
    }
}