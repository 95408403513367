.terms-wrapper {
    width: 100%;
    height: 100%;
    font-family: 'Titillium Web', sans-serif;
}

.terms-wrapper h3 {
    color: #1C2D5A;
    font-family: "Titillium Web";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.terms-wrapper h4 {
    color: #1C2D5A;
    font-family: "Titillium Web";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.terms-wrapper ol li {
    font-size: 20px;
    font-weight: 700;
    color: #1C2D5A;
    font-family: "Titillium Web";
}

.terms-wrapper ul li {
    font-size: 18px;
    font-weight: 400;
    font-family: 'Titillium Web', sans-serif;
}

.terms-wrapper p {
    font-size: 18px;
    font-weight: 400;
    font-family: 'Titillium Web', sans-serif;
}

.terms-wrapper a {
    color: blue
}

.terms-wrapper a:visited {
    color: rgb(77, 77, 173)
}

.terms-wrapper a:hover {
    color: #E0871A
}

@media(max-width:450px) {
    .terms-wrapper h3 {
        color: #1C2D5A;
        font-family: "Titillium Web";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }
    .terms-wrapper h4 {
        color: #1C2D5A;
        font-family: "Titillium Web";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }
    .terms-wrapper ol li {
        font-size: 18px;
        font-weight: 700;
        color: #1C2D5A;
        font-family: "Titillium Web";
    }
    .terms-wrapper ul li {
        font-size: 14px;
        font-weight: 400;
        font-family: 'Titillium Web', sans-serif;
    }
    .terms-wrapper p {
        font-size: 14px;
        font-weight: 400;
        font-family: 'Titillium Web', sans-serif;
    }
}

@media(min-width:450px) and (max-width:680px) {
    .terms-wrapper h3 {
        color: #1C2D5A;
        font-family: "Titillium Web";
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }
    .terms-wrapper h4 {
        color: #1C2D5A;
        font-family: "Titillium Web";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }
    .terms-wrapper ol li {
        font-size: 18px;
        font-weight: 700;
        color: #1C2D5A;
        font-family: "Titillium Web";
    }
    .terms-wrapper ul li {
        font-size: 14px;
        font-weight: 400;
        font-family: 'Titillium Web', sans-serif;
    }
    .terms-wrapper p {
        font-size: 14px;
        font-weight: 400;
        font-family: 'Titillium Web', sans-serif;
    }
}