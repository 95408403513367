.newsletterHeaderWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 112px;
    margin-bottom: 80px;
}


/* Header */

.newsletterMainHeader {
    display: flex;
    max-width: 768px;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
    margin-bottom: 80px;
}

.newsletterMainHeader h6 {
    color: #58595B;
    text-align: center;
    font-family: "Titillium Web", "Roboto";
    ;
    font-size: 30px;
    font-style: normal;
    font-weight: 275;
    line-height: 150%;
    margin: 0px;
}

.newsletterMainHeader h2 {
    color: #223476;
    text-align: center;
    font-family: "Titillium Web", "Roboto";
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0px;
}

.newsletterMainHeader p {
    color: #58595B;
    text-align: center;
    font-family: Corbel, "Roboto";
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0px;
}


/* Cards */

.newsletterButtonsWrapper {
    min-height: 44px;
    display: flex;
    align-items: center;
    column-gap: 24px;
    margin-bottom: 64px;
}

.newsletterCardsWrapper {
    width: 90%;
    max-width: 1312px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    column-gap: 32px;
    row-gap: 64px;
}


/* Get questions answered section */

.getQuestionsAnswersWrapper {
    width: 100%;
    background: #25BDBE;
    display: flex;
    align-items: center;
    justify-content: center;
}

.getQuestionsAnswersContainer {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.getQuestionsAnswersSignup {
    display: flex;
    width: 768px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    margin-top: 112px;
    margin-bottom: 112px;
}

.getQuestionsAnswersSignup h3 {
    color: var(--Main-color1, #1C2D5A);
    font-family: "Titillium Web", "Roboto";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0px;
}

.getQuestionsAnswersSignup p {
    color: #FFF;
    font-family: Corbel, "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0px;
}

.getQuestionsAnswersIcon {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.getQuestionsAnswersIcon svg {
    width: 70%;
}

@media(max-width: 680px) {
    .newsletterHeaderWrapper {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    .newsletterButtonsWrapper {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        row-gap: 24px;
        column-gap: 24px;
        margin-bottom: 50px;
    }
    .newsletterMainHeader {
        margin-top: 0px;
        margin-bottom: 40px;
    }
    .newsletterMainHeader h6 {
        font-size: 20px;
    }
    .newsletterMainHeader h2 {
        font-size: 30px;
    }
    .newsletterMainHeader p {
        max-width: 90%;
        font-size: 18px;
    }
    .getQuestionsAnswersContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        row-gap: 24px;
    }
    .getQuestionsAnswersSignup {
        display: flex;
        width: 90%;
        flex-direction: column;
        align-items: center;
        row-gap: 16px;
        text-align: center;
        margin-top: 60px;
        margin-bottom: 30px;
    }
    .getQuestionsAnswersSignup h3 {
        font-size: 30px;
    }
    .getQuestionsAnswersSignup p {
        font-size: 14px;
    }
    .getQuestionsAnswersIcon {
        width: 60%;
        height: auto;
        justify-content: center;
        margin-bottom: 60px;
    }
    .getQuestionsAnswersIcon svg {
        width: 80%;
        height: 80%;
    }
    .newsletterCardsWrapper {
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
    }
}

@media(min-width: 680px) and (max-width:1100px) {
    .getQuestionsAnswersContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 24px;
    }
    .getQuestionsAnswersSignup {
        display: flex;
        width: 90%;
        flex-direction: column;
        align-items: center;
        row-gap: 16px;
        text-align: center;
        margin-top: 60px;
        margin-bottom: 30px;
    }
    .getQuestionsAnswersIcon {
        margin-bottom: 60px;
    }
    .newsletterCardsWrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}