.highlight-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 112px;
    padding-top: 112px;
}

.highlight-container {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.highlight-content-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.highlight-content-title h2 {
    color: #223476;
    font-family: "Titillium Web", "Roboto";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-top: 0px;
    margin-bottom: 0px;
}

.highlight-content-subtitle {
    border-left: 10px solid #E0871A;
    padding-left: 30px;
}

.highlight-content-subtitle p {
    max-width: 616px;
    font-family: Corbel, "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.highlight-icon {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media(max-width:680px) {
    .highlight-wrapper {
        padding-bottom: 60px;
        padding-top: 60px;
    }
    .highlight-container {
        flex-direction: column;
    }
    .highlight-content-title h2 {
        font-size: 30px;
    }
    .highlight-content-subtitle p {
        font-size: 14px;
    }
    .highlight-icon {
        width: 100%;
        max-height: 400px;
        font-size: 44px;
        margin-top: 1rem;
    }
    .highlight-icon img {
        width: 50%;
        height: 100%;
    }
}

@media(min-width:680px) and (max-width: 900px) {
    .highlight-container {
        flex-direction: column;
    }
    .highlight-content-title h2 {
        font-size: 30px;
    }
    .highlight-content-subtitle p {
        font-size: 14px;
    }
    .highlight-icon {
        width: 100%;
        max-height: 400px;
        font-size: 44px;
        margin-top: 1rem;
    }
    .highlight-icon img {
        width: 50%;
        height: 100%;
    }
}


/* @media(min-width:650px) and (max-width:900px) {
.highlight-content-title h3 {
    font-size: 44px;
}
.highlight-icon img {
    width: 100%;
}
} */